'use strict'
import { Content } from './content';

export default class Navigate {
    constructor() {
    }
    init() {
        this.content = new Map();
        this.content.set("welcome", new Content("welcome"));
        this.content.set("about", new Content("about"));
        this.content.set("lrs", new Content("lrs"));
        this.content.set("dys", new Content("dys"));
        this.content.set("eng", new Content("eng"));
        this.content.set("contact", new Content("contact"));
        this.content.set("bdsg", new Content("bdsg"));
        this.content.set("impressum", new Content("impressum"));

        this.content.get("welcome").show();
        this.createEvents();
    }
    navigate(here) {
        const parts = here.split("/");
        // iterate over content and check if address is found
        let found = false;
        for (const [k,v] of this.content.entries()) {
            if (k !== parts[0]) {
                v.hide();
            }
        }
        for (const [k,v] of this.content.entries()) {
            if (k === parts[0]) {
                v.show(parts);
                found = true;
            }
        }
        if (!found) {
            this.content.get("welcome").show();
        }
    }
    createEvents() {
        window.staticEvents.addListener('navigate', (data) => {
            this.navigate(data);
        });
    }
}
