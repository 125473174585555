'use strict'

export class Content {
    constructor(id) {
        this.id = id;
        this.currentIndex = 0;
        this.init();
    }
    init() {
        this.content = $(`#con_${this.id}`);
        this.nodes = this.content.find('.node');
        this.createEvents();
        this.hide();
    }
    createEvents() {
        //if ( this.nodes.length > 1 ) {
            //this.leftArrow = this.content.find('.leftArrow').first();
            //this.rightArrow = this.content.find('.rightArrow').first();
            //this.leftArrow.click(() => {
                //this.currentIndex = (this.currentIndex - 1 + this.nodes.length) % this.nodes.length;
                //this.showIndex();
            //});
            //this.rightArrow.click(() => {
                //this.currentIndex = (this.currentIndex + 1) % this.nodes.length;
                //this.showIndex();
            //});
        //}
        this.content.find('.button.link').click((e) => {
            const target = $(e.currentTarget).attr('data-target');
            window.staticEvents.emit('navigate',target);
        });
        this.content.scroll((e) => {
            this.evaluateGradient();
        });
    }
    evaluateGradient() {
        var total = -1 * $('#container').height();
        var contentHeight = 0;
        this.content.find('.node').each((i,n) => {
        	contentHeight += $(n).outerHeight(true);
        });
        total += contentHeight;
        total -= 10; // margin to trigger
        var scrollTop = this.content.scrollTop();
        if (scrollTop > 0 && scrollTop < total) {
            $('#topFade').not('.show').addClass('show');
            $('#bottomFade').not('.show').addClass('show');
        } else if (scrollTop > 0) {
            $('#topFade').not('.show').addClass('show');
            $('#bottomFade').removeClass('show');
        } else if (scrollTop < total) {
            $('#topFade').removeClass('show');
            $('#bottomFade').not('.show').addClass('show');
        } else {
            $('#topFade').removeClass('show');
            $('#bottomFade').removeClass('show');
        }
    }
    show() {
        this.content.appendTo("#container");
        const targetClass = `.button.menu_${this.id}`;
        $(targetClass).not('.active').addClass('active');
        this.currentIndex = 0;
        this.showIndex();
        this.evaluateGradient();
    }
    hide() {
        this.content.detach();
        $(`.button.menu_${this.id}`).removeClass('active');
    }
    showIndex(index=this.currentIndex) {
        if (index !== this.currentIndex) {
            this.currentIndex = index;
        }
        $('.node').not(`#${this.id}_node_${index}`).removeClass('active');
        $(`#${this.id}_node_${index}`).addClass('active');
        this.content.find('.number').removeClass('active');
        this.content.find(`.number_${this.currentIndex}`).addClass('active');
    }
}
