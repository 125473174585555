'use strict';

// ::::::::::::::::::::::::::::::::::::::::::::: imports
import { EventEmitter, Timer, getBrowserVersion, cssExtras } from './classes';
import $ from 'jquery';
import MobileDetect from 'mobile-detect';

import Cms from './cms';

import Navigate from './navigate';
import Menu from './menu';
import Message from './message';

// ::::::::::::::::::::::::::::::::::::::::::::: globals
window.$ = $;
window.staticEvents = new EventEmitter();

// ::::::::::::::::::::::::::::::::::::::::::::: variables
let isMobile = false;

const menu = new Menu();
const navigate = new Navigate();
const cms = new Cms();
//const message = new Message();

// ::::::::::::::::::::::::::::::::::::::::::::: functions

const init = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile() !== null) {
	    $('body').addClass('mobile');
        isMobile = true;
    } else {
	    $('body').addClass('desktop');
    }

	$(window).resize(() => {
        resize();
	});
    resize();

    menu.init();
    navigate.init();
    //message.init();
    //window.staticEvents.emit('navigate','about');
}

const resize = () => {
    if (!isMobile) {
        if($(window).width() < 600) {
            $('body').not('.mobile').addClass('mobile');
            $('body').removeClass('desktop');
        } else {
            $('body').not('.desktop').addClass('desktop');
            $('body').removeClass('mobile');
        }
    }
}

// ::::::::::::::::::::::::::::::::::::::::::::: initialization


$(document).ready(() => {
	init();
});



/*********************************************** help

// ::::::::::::::::::::::::::::::::::::::::::::: app.js

	app.js is the main class.

	it collects user and window information,
	and fires up viewController

 ************************************************/
