'use strict';

// UTIL

export const getBrowser = () => {
	const N = navigator.appName;
	const ua = navigator.userAgent;
	let tem;
	let M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
	if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
	M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
	return M[0];
};

export const getBrowserVersion = () => {
	const N = navigator.appName;
	const ua = navigator.userAgent;
	let tem;
	let M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
	if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
	M =  M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
	return M[1];
};

export function cEnum(obj) {
	const keysByValue = new Map();
	const EnumLookup = (value) => { keysByValue.get(value); };

	for (const key of Object.keys(obj)) {
		EnumLookup[key] = obj[key];
		keysByValue.set(EnumLookup[key], key);
	}

	// Return a function with all your enum properties attached.
	// Calling the function with the value will return the key.
	return Object.freeze(EnumLookup);
}

function clamp(value, outMin, outMax) {
	const min = Math.max(outMin, outMax);
	const max = Math.min(outMin, outMax);
	return Math.min(min, Math.max(max, value));
}

export function mapTo(value, inMin, inMax, outMin, outMax, bClamp = true) {
	const base = (value - inMin) / (inMax - inMin);
	const mapped = (outMax - outMin) * base + outMin;
	return bClamp ? clamp(mapped, outMin, outMax) : mapped;
}


export class DataParser {
	constructor(filePath,callback) {
		const request = new XMLHttpRequest();
		request.open('GET', filePath, false);
		request.send(null);
		const data = callback(JSON.parse(request.responseText));
		console.log('data:');
		console.log(data);
		Object.assign(this, data);
	}
}
// css letter super function
export function cssExtras() {
    (function($) {
        $.fn.nthEverything = function() {
         var styleSuffix = "-nthEvery",
           cssPattern = /\s*(.*?)\s*\{(.*?)\}/g,
           cssComments = /\s*(?!<")\/\*[^\*]+\*\/(?!")\s*/gm,
           partsPattern = /([^:]+)/g,
           nthPattern = /(\w*)-(\w*)(\((even|odd|[\+-n\d]{1,6})\))?/,
           count, parsedStyleMap = {}, genCSS = '',
           runPeriods = function(period, className, a, length, offset) {
             var inBy = 0, sAt = Number(period), n, zB, zE, bF, eF, oldN = -1;
             if (period === 'odd' || period === 'even') {
               sAt = (period === 'odd') ? 1 : 2;
               inBy = 2;
             } else if (/^\d+$/.test(period)) {
               sAt = period - offset, inBy = 0;
             } else {
               zB = (/^(\+|-)?\d+/).exec(period);
               zE = (/(\+|-)?\d+$/).exec(period);
               sAt = (zE) ? Number(zE[0]) : 1;
               inBy = (zB) ? Number(zB[0]) : 1;
               bF = (zB) ? zB.length - 1 : 0;
               eF = (zE) ? zE.length : 0;
               if ((period.substr(bF, period.length - eF - bF).charAt(0)) === '-')
                 inBy *= -1;
             }
             // Start index at 0;
             for (n = --sAt; n < length; n += inBy) {
               if (n < 0 || n === oldN) break;
               if (a[n] == null) a[n] = className;
               else a[n] += " " + className;
               oldN = n;
             }
           }, createSpan = function(className, content) {
             return '<span class="' + className + '">' + content + '</span>';
           }, processPeriod = function(classNames, textArray) {
             var newText = '', n, className;
             for (n = 0; n < classNames.length; n++) {
               className = classNames[n];
               if (className == null) newText += textArray[n];
               else newText += createSpan(className, textArray[n]);
             }
             return newText;
           }, prepareTxt = {
             letter: function(text) {
               return text.split('');
             }
           }, pseudoFunc = {
             first: {}, last: {}, nth: {
               letter: function(period) {
                 return period;
               }
             }
           }, loopRecursive = function(contents, allText, parsedStyle) {
             var func = parsedStyle.func,
               text, length, classNames, className, cat, period;
             contents.each(function() {
               if (this.nodeType === 1) {
                 loopRecursive($(this).contents(), allText, parsedStyle);
               } else if (this.nodeType === 3) {
                 text = prepareTxt[func](this.nodeValue);
                 length = text.length;
                 classNames = new Array(length);
                 for (var i = 0; i < parsedStyle.period.length; i++) {
                   className = parsedStyle.className[i];
                   cat = parsedStyle.cat[i];
                   period = parsedStyle.period[i];
                   runPeriods(pseudoFunc[cat][func](period, allText, length), className, classNames, length, count);
                 }
                 $(this).replaceWith(processPeriod(classNames, text));
                 count += length;
               }
             });
             return count;
           }, parse = function(css) {
             var matches, nthMatch, nthFound = false,
               i, thisPeriod, selectors, style, selector, parts, nth, pseudo, cat, func, period, normSelector, ident, className;
             css = css.replace(cssComments, '').replace(/\n|\r/g, '');
             while ((matches = cssPattern.exec(css)) !== null) {
               selectors = matches[1].split(',');
               style = matches[2];
               for (i = 0; i < selectors.length; i++) {
                 selector = selectors[i];
                 parts = selector.match(partsPattern);
                 selector = parts.shift();
                 nth = parts.shift();
                 pseudo = (parts.length !== 0) ? ':' + parts.join(':') : '';
                 if ((nthMatch = nthPattern.exec(nth)) !== null) {
                   nthFound = true;
                   cat = nthMatch[1];
                   func = nthMatch[2];
                   period = (nthMatch[4] != null) ? nthMatch[4] : cat + func;
                   normSelector = selector.replace('#', 'id').replace('.', 'class');
                   ident = normSelector + func;
                   className = normSelector + cat + func + period + styleSuffix;
                   if ((thisPeriod = parsedStyleMap[ident]) != null) {
                     thisPeriod.className.push(className);
                     thisPeriod.period.push(period);
                     thisPeriod.style.push(style);
                     thisPeriod.pseudo.push(pseudo);
                     thisPeriod.cat.push(cat);
                   } else {
                     parsedStyleMap[ident] = {
                       element: selector,
                       func: func,
                       className: [className],
                       cat: [cat],
                       period: [period],
                       style: [style],
                       pseudo: [pseudo]
                     };
                   }
                 } else if (nthFound === true) {
                   genCSS += selector + "{" + style + "}";
                 }
               }
             }
           }, applyStyles = function() {
             var id, parsedStyle, b;
             for (id in parsedStyleMap) {
               parsedStyle = parsedStyleMap[id];
               func = parsedStyle.func;
               $(parsedStyle.element).each(function() {
                 var $this = $(this); count = 0;
                 loopRecursive($this.contents(), $this.text(), parsedStyle);
               });
               for (b = 0; b < parsedStyle.className.length; b++) {
                   console.log(b);
                 genCSS += "." + parsedStyle.className[b] + parsedStyle.pseudo[b] + "{" + parsedStyle.style[b] + "}";
               }
             }
             $('<style>' + genCSS + '</style>').appendTo('head');
           };
         $('link[rel=stylesheet],style').each(function() {
           if ($(this).is('link')) $.get(this.href).success(function(css) { parse(css); });
           else parse($(this).text());
         }); applyStyles();
        };
        console.log('css Extras loaded 1');
    })($);
    $(function() {
        $.fn.nthEverything();
        console.log('css Extras loaded soso');
    });
}

export class EventEmitter {

    constructor() {
        this.listeners = new Map();
    }

    addListener(label, callback) {
        this.listeners.has(label) || this.listeners.set(label, []);
        this.listeners.get(label).push(callback);
    }

    removeListener(label, callback) {
        let listeners = this.listeners.get(label),
            index;

        if (listeners && listeners.length) {
            index = listeners.reduce((i, listener, index) => {
                return (isFunction(listener) && listener === callback) ?
                    i = index :
                    i;
            }, -1);

            if (index > -1) {
                listeners.splice(index, 1);
                this.listeners.set(label, listeners);
                return true;
            }
        }
        return false;
    }
    emit(label, ...args) {
        let listeners = this.listeners.get(label);
        if (listeners && listeners.length) {
            listeners.forEach((listener) => {
                listener(...args);
            });
            return true;
        }
        return false;
    }
}

export class Observer {
  constructor(id) {
    this.id = id;
  }
  onChange(data) {
    console.log(`${this.id} notified of change:`, data);
  }
}

