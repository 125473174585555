'use strict'

export default class Menu {
    constructor() {
    }
    init() {
        this.container = $('#menu'); 
        this.createEvents();
    }
    createEvents() {
        this.container.find('.button').click((e) => {
            const target = $(e.currentTarget).attr('data-target');
            window.staticEvents.emit('navigate',target);
            $('#mobileMenu').removeClass('active');
        });
        $('#footer').find('.button').click((e) => {
            const target = $(e.currentTarget).attr('data-target');
            window.staticEvents.emit('navigate',target);
            $('#mobileMenu').removeClass('active');
        });
        this.container.find('#mobileCross').click((e) => {
            $('#mobileMenu').toggleClass('active');
        });
        $('body').not('#mobileMenu').click((e) => {
            //$('#mobileMenu').removeClass('active');
            
        });
        document.addEventListener('touchmove', function(e) {
            var touch = e.touches[0];
            console.log(touch.pageX + " - " + touch.pageY);
            console.log(e);
        }, false);
        document.addEventListener('touchstart', function(e) {
            var touch = e.touches[0];
            console.log(e);
        }, false);
    }
}
