'use strict'

export default class Message {
    constructor() {
    }
    init() {
        this.container = $('#message'); 
        this.createEvents();
    }
    createEvents() {
        this.container.find('.close').click((e) => {
            this.container.remove();
        });
    }
}
